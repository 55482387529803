* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

body,
html {
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  font-family:
    'Raleway',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.my-atropos {
  width: 320px;
  height: 160px;
}
