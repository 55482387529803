/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
h1, h2 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
/* windicss layer components */

/* windicss layer utilities */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.dark [bg~="dark:background-dark"] {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 20, 24, var(--tw-bg-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
[cursor~="pointer"] {
  cursor: pointer;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.hidden {
  display: none;
}
[flex~="row"] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
[flex~="col"] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
[flex~="col-reverse"] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
[justify~="center"] {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
[justify~="between"] {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
[order~="1"] {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
[order~="2"] {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
[order~="3"] {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.font-900 {
  font-weight: 900;
}
[font~="900"] {
  font-weight: 900;
}
[font~="600"] {
  font-weight: 600;
}
[font~="500"] {
  font-weight: 500;
}
[font~="extrabold"] {
  font-weight: 800;
}
[font~="semibold"] {
  font-weight: 600;
}
[h~="full"] {
  height: 100%;
}
[h~="100vh"] {
  height: 100vh;
}
[h~="50vh"] {
  height: 50vh;
}
[text~="2xl"] {
  font-size: 1.5rem;
  line-height: 2rem;
}
[text~="xs"] {
  font-size: 0.75rem;
  line-height: 1rem;
}
[text~="sm"] {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
[text~="5xl"] {
  font-size: 3rem;
  line-height: 1;
}
.text-4rem {
  font-size: 4rem;
  line-height: 1;
}
[text~="4.5vh"] {
  font-size: 4.5vh;
  line-height: 1;
}
[text~="1.5rem"] {
  font-size: 1.5rem;
  line-height: 1;
}
[text~="2rem"] {
  font-size: 2rem;
  line-height: 1;
}
[text~="1rem"] {
  font-size: 1rem;
  line-height: 1;
}
[text~="1.3rem"] {
  font-size: 1.3rem;
  line-height: 1;
}
.leading-7 {
  line-height: 1.75rem;
}
[m~="auto"] {
  margin: auto;
}
[m~="4"] {
  margin: 1rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
[m~="x-auto"] {
  margin-left: auto;
  margin-right: auto;
}
[m~="r-2"] {
  margin-right: 0.5rem;
}
[m~="t-auto"] {
  margin-top: auto;
}
[m~="b-0"] {
  margin-bottom: 0px;
}
[m~="r-3"] {
  margin-right: 0.75rem;
}
[m~="!r-0"] {
  margin-right: 0px !important;
}
[m~="l-4"] {
  margin-left: 1rem;
}
.max-h-40vh {
  max-height: 40vh;
}
.max-w-11\/12 {
  max-width: 91.666667%;
}
[w~="max-10/12"] {
  max-width: 83.333333%;
}
[overflow~="hidden"] {
  overflow: hidden;
}
[p~="2"] {
  padding: 0.5rem;
}
[p~="3"] {
  padding: 0.75rem;
}
[p~="4"] {
  padding: 1rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.top-15vh {
  top: 15vh;
}
.top-41vh {
  top: 41vh;
}
.top-0 {
  top: 0px;
}
.bottom-0 {
  bottom: 0px;
}
[text~="left"] {
  text-align: left;
}
.text-center {
  text-align: center;
}
[text~="center"] {
  text-align: center;
}
[text~="hover:light-blue-500"]:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}
[text~="hover:yellow-500"]:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.dark [text~="dark:white"] {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
[text~="hover:indigo-500"]:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
[text~="hover:blue-500"]:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
[text~="underline"] {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[select~="none"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.visible {
  visibility: visible;
}
[w~="full"] {
  width: 100%;
}
[w~="100vw"] {
  width: 100vw;
}
[z~="1"] {
  z-index: 1;
}
.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
[transition~="colors"] {
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.animated {
  -webkit-animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.blur {
  --tw-blur: blur(8px);
}
@media (max-width: 1023.9px) {
  .\<lg\:text-2\.5rem {
    font-size: 2.5rem;
    line-height: 1;
  }
  .\<lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .\<lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 767.9px) {
  .\<md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 639.9px) {
  [flex~="<sm:col"] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  [order~="<sm:3"] {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  [order~="<sm:1"] {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  [order~="<sm:2"] {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  [w~="<sm:max-12/12"] {
    max-width: 100%;
  }
  .\<sm\:left-20vw {
    left: 20vw;
  }
  [w~="<sm:80vw"] {
    width: 80vw;
  }
  [w~="<sm:60vw"] {
    width: 60vw;
  }
  .\<sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .\<sm\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .\<sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .\<sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}